import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCloseModal } from "../../store";
import { useTranslation } from "react-i18next";
import ItemBarcode from "../../components/items/ItemBarcode";
import PlaylistElement from "../../components/playlistElements/PlaylistElement";


const DeviceBarcodesModal = () => {
  // what the fuck did I do here?
  const modal = useSelector(state => state.deviceItemsModal);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  console.log(modal)
  const dispatch = useDispatch();
  const {t} = useTranslation();
  

  return (
    <div className="modal bg-black bg-opacity-40">
      <div className="relative  w-full my-6 mx-auto  max-w-3xl overflow-scroll mt-[50px]">
        <div className=" border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white 
          outline-none focus:outline-none p-9 justify-center items-center overflow-scroll"
        >
          <div className="w-full grid grid-cols-4 p-2">
            {modal.displays.map(dis => <PlaylistElement element={dis}/>)}
          </div>
          <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
            <button
              className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
              type="button"
              onClick={() => {
                dispatch(setCloseModal())
              }}
            >
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceBarcodesModal