import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseIcon from '../../static/crossB.png';
import { editStoreApi } from '../../api/storesApi';

const StoreEditModal = ({storeToEdit, setShowModal}) => {
  const [store, setStore] = useState({
    storeId:storeToEdit.storeId,
    name:storeToEdit.name,
    zkongAccount: storeToEdit.zkongAccount,
    zkongPassword:"",
    zkongStoreId:storeToEdit.zkongStoreId,
    zkongMerchantId: storeToEdit.zkongMerchantId,
  });

  const user = useSelector(state => state.user);
  const {t} = useTranslation();
  const submitAdmin = async () => {
    try {
      if(!store.storeId) return;
      if(!store.name) return;
      await editStoreApi(user.token, store);
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="relative flex flex-col bg-white items-center rounded-lg shadow dark:bg-gray-700 w-[80%] md:w-[40%] p-5">
            <button
              className='cursor-pointer absolute left-[92%] md:left-[95%] z-20'
              onClick={() => setShowModal(false)}
            >
              <img src={CloseIcon}/>
            </button>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Name
              </p>
              <input 
                className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                value={store.name}
                name="code"
                onChange={(e) => setStore(store => ({...store, "name":e.target.value}))}
              />
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Zkong Account
              </p>
              <input 
                className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                value={store.zkongAccount}
                name="code"
                onChange={(e) => setStore(store => ({...store, "zkongAccount":e.target.value}))}
              />
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Zkong Password
              </p>
              <input 
                className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                type='password  '
                value={store.zkongPassword}
                name="code"
                onChange={(e) => setStore(store => ({...store, "zkongPassword":e.target.value}))}
              />
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Zkong Store Id
              </p>
              <input 
                className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                value={store.zkongStoreId}
                name="code"
                onChange={(e) => setStore(store => ({...store, "zkongStoreId":e.target.value}))}
              />
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Zkong Merchant Id
              </p>
              <input 
                className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                value={store.zkongMerchantId}
                name="code"
                onChange={(e) => setStore(store => ({...store, "zkongMerchantId":e.target.value}))}
              />
            </div>
            <div className="flex items-center justify-center p-3 border-slate-200 rounded-b">
              <button
                className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                type="button"
                onClick={submitAdmin}
              >
                {t("send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreEditModal