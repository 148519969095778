
import { useDispatch, useSelector } from 'react-redux';
import ScanQr from '../../static/developer_mode_tv.png';
import Menu from '../../static/menu.png';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import UserSelect from '../admin/UserSelect';
import Cross from '../../static/cross.png';
import Logout from '../../static/logout.png';
import Back from '../../static/chevron_right.png';
import ESL from '../../static/driver.png';
const Header = ({showBack, backTitle}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const onLogout = () => {
    localStorage.removeItem("token");
    dispatch(logoutUser());

  }
  

  return (
    <div className='flex flex-row bg-[#2D5186] p-5 w-full justify-start items-center overflow-x-clip'>
      {showBack && <div className='flex cursor-pointer' onClick={() => {navigate(-1)}}>
        <img  src={Back}/>
        <h2 className='text-white font-bold'>{t(backTitle)}</h2>
      </div>}
      {user.superAdmin && <UserSelect/>}
      <div className='fixed left-[91%] md:left-[98%] z-10'>
        <button
          className=""
          type="submit"
          onClick={() => setShowMenu(!showMenu)}
        >
          <img src={Menu}/>
        </button>
        {<div className={`${showMenu ? "left-[-160px]" : "left-[160px]"} top-[-25px] absolute w-[200px] h-[240px] bg-[#525252] z-10 p-5 transition-all border-gray-500 border-[2px]`}>
          <div className='w-full h-full flex flex-col justify-between items-end'>
            <button
              className='text-white font-semibold mt-2'
              onClick={() => setShowMenu(false)}
            >
              <img src={Cross} width={15}/>
            </button>
            <Link to={'/scannerBind'}>
              <button
                className='flex justify-between items-start w-full text-white font-semibold flex-end'
              >
                <h2 className='mx-5 text-white text-md'>ESL Bind</h2>
                <img src={ESL} width={25} height={15}/>
              </button>
            </Link>
            <Link to={'/qrScan'}>
              <button
                className='flex justify-between items-start w-full text-white font-semibold flex-end'
              >
                <h2 className='mx-5 text-white text-md'>QrCode</h2>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 8V16" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 8V12" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 15V16" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15 8V9" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 8V16" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15 12V16" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18 8V16" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> 
              </button>
            </Link>
            <button
              className='text-white font-semibold flex-end'
              onClick={onLogout}
            >
                
              <img src={Logout} width={25}/>
            </button>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default Header