import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getStoresApi} from "../../api/storesApi"
import Select from "react-tailwindcss-select";
import { setSelectedStore } from "../../store";


const UserSelect = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const storeSelection = useSelector(state => state.storeSelection);
  const [StoreFilter, setStoreFilter] = useState(storeSelection.selectedStore);
  const [stores, setStores] = useState([]);
  const [storeDic, setStoreDic] = useState([]);

  useEffect(() => {
    getStoresApi(user.token, url)
      .then(res => {
        setStores(res);
        let storeLookup = {}
        res.forEach(store => {
          storeLookup[store.storeId] = `${store.storeId} ${store.name ? `(${store.name})` :""}`
        });
        setStoreDic(storeLookup);
      })
      .catch(err => console.log(err))
  }, []);

  return (
    <div className='relative  md:h-[20%] flex flex-col  justify-center items-center text-center'>
        <div className='flex flex-row w-full gap-2 justify-center items-center px-4'>
          <h2 className='text-white w-[100px] font-bold text-md'>Store:</h2>
          <Select
            options={
              stores.map(storeId => 
                ({value:storeId.storeId, label:`${storeId.storeId} ${storeId.name ? `(${storeId.name})` :""}`}))
            }
            value={{value:StoreFilter, label:storeDic[StoreFilter]}}
            onChange={(selection)=> {
              setStoreFilter(selection.value);
              dispatch(setSelectedStore(selection.value));
            }}
            classNames={{
              menu:"absolute w-[250px] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
              menuButton:() => "flex flex-row w-[250px] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>
        
      </div>
  )
}

export default UserSelect