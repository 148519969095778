import React, { useState } from 'react'
import EditIcon from '../../static/stylus.png';
import RemoveIcon from '../../static/delete.png';


const UploadRow = ({upload, setDeleteDialog,setEditNameDialog}) => {
  return (
    <tr 
      className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100
      dark:border-neutral-500 dark:hover:bg-neutral-600"
    >
      <td className="text-black font-bold border-r-2 p-2 flex flex-col justify-center ">
        <div className='w-full flex items-center justify-center gap-2'>
          
          {upload.type === "image" && <img
            src={upload.link}
            className='w-full rounded-lg'
          />}
          {upload.type === "video" && <video width="400" controls="controls" preload="metadata" className='w-full rounded-lg'>
            <source src={upload.link} type="video/mp4" />
          </video>}
        </div>
      </td>
      <td className='text-black font-bold border-r-2 p-[10%] md:p-2'>
        {upload.name}
      </td>
      <td className='text-black font-bold border-r-2 md:p-2 hidden md:table-cell'>
        {upload.link}
      </td>
      <td className='text-black font-bold border-r-2 md:p-2'>
        <div className='w-full flex items-center justify-center gap-2'>
          <button
            onClick={() => {
              setEditNameDialog({
                show:true,
                link: upload.link
              })
            }}
          >
            <img src={EditIcon} className='w-5'/>
          </button>
          <button
            onClick={()=> {
              setDeleteDialog({
                show:true,
                link: upload.link
              })
            }}
          >
            <img src={RemoveIcon} className='w-5'/>
          </button>
        </div>
      </td>
    </tr>
  )
}

export default UploadRow