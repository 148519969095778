import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getItem, updateItem } from '../api/itemsApi';
import Select from 'react-tailwindcss-select';
import Loading from '../modals/Loading';
import { useSelector } from 'react-redux';
import Upload from '../static/upload.png'
import AddImage from '../static/gallery-add.png';
import AddVideo from '../static/video-horizontal.png';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { uploadImage, uploadPdf, uploadPowerPoint, uploadVideo } from '../api/uploadApi';
import Header from '../components/layout/Header';
import { getStoreDetails } from '../api/storesApi';
import { addImageToZkong } from '../api/zkongApi';
import PlaylistElement from '../components/playlistElements/PlaylistElement';
import UploadModal from '../modals/upload/UploadModal';
import galleryIcon from '../static/gallery.png';
const templates = [
  {value:"default", label:"default"},
  {value:"Temp1", label:"Temp1"},
  {value:"Temp2", label:"Temp2"},
  {value:"Temp3", label:"Temp3"},
  {value:"Temp4", label:"Temp4"},
  {value:"Temp5", label:"Temp5"},
  {value:"Temp6", label:"Temp6"},
  {value:"Temp7", label:"Temp7"}
]


const ItemEditPage = ({}) => {
  const {itemCode} = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  
  const [imageError, setImageError] = useState('');
  const [videoError, setVideoError] = useState('');
  const [imageVideoPreview, setImageVideoPreview] = useState({
    img:"",
    video:""
  });
  const [uploadSelectModal, setUploadSelectModal] = useState({
    show:false,
    type:"",
  });

  const [loadingModal, setLoadingModal] = useState(false);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const [item, setItem] = useState(null);
  console.log(item);
  useEffect(() => {
    getItem(user.token,url, itemCode, selectedStore)
      .then(res => {
        setItem(res);
        setImageVideoPreview({
          img: res.imageLink ? res.imageLink : "",
          video: res.videoLink ? res.videoLink : ""
        })
      })
      .catch(err => console.log(err));
  }, [])

  const onChange = (e) => {
    setItem(item => ({...item, [e.target.name]:e.target.value}))
  }

  const onSubmit = async () => {
    try {
      await updateItem(user.token, url, item);
      const storeData = await getStoreDetails(user.token, item.storeId);
      if(
        storeData.zkongStoreId &&
        storeData.zkongMerchantId &&
        storeData.zkongPicId
      ) {
        setLoadingModal(true);
        await addImageToZkong(
          user.token,
          {
            storeId: item.storeId,
            imageLink: item.imageLink,
            barcode: item.barcode
          }
        );
      }
      setLoadingModal(false);
      navigate(-1)
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingModal(false);
    }
  }
  const imageUploadHandle = async (e) => {
    const file = e.target.files[0];
    if(file.type != "image/jpeg" && file.type != "image/png") {
      setImageError("Images must be .jpgs or .pngs only")
      setTimeout(() => {
        setImageError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', item.admin);
    form.append('selectedStore', item.storeId);
    setLoadingModal(true);
    try {
      const result = await uploadImage(user.token, url, form);
      setItem(item => ({...item, imageLink:result.link}));
      setImageVideoPreview(preview => ({...preview, img:result.link}));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const powerpointUploadHandle = async (e) => {
    const file = e.target.files[0];
    
    const form = new FormData();
    form.append('file', file);
    form.append('admin', item.admin);
    form.append('selectedStore', item.storeId);
    setLoadingModal(true);
    try {
      const result = await uploadPowerPoint(user.token, url, form);
      setItem(item => ({...item, powerPointLink:result.link}));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const uploadPdfHandle = async (e) => {
    const file = e.target.files[0];
    
    const form = new FormData();
    form.append('file', file);
    form.append('admin', item.admin);
    form.append('selectedStore', item.storeId);
    setLoadingModal(true);
    try {
      const result = await uploadPdf(user.token, url, form);
      setItem(item => ({...item, pdfLink:result.link}));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const videoUploadHandle = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    if(file.type != "video/mp4") {
      setVideoError("Videos can be mp4 only")
      setTimeout(() => {
        setVideoError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', item.admin);
    form.append('selectedStore', item.storeId);
    setLoadingModal(true);
    try {
      const result = await uploadVideo(user.token, url, form);
      setItem(item => ({...item, videoLink:result.link}));
      setImageVideoPreview(preview => ({...preview, video:result.link}));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const {t} = useTranslation()

  return (
    <>
      {uploadSelectModal.show && <UploadModal 
        type={uploadSelectModal.type} 
        setItem={setItem}
        setShowModal={(show) => setUploadSelectModal(upSel => ({...upSel, show: show}))}
        setPreview={setImageVideoPreview}
      />}
      <Header showBack={true} backTitle={""}/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='bg-white w-full h-full flex flex-col justify-start items-center p-5 gap-2 overflow-scroll pb-20'>
        {loadingModal && <Loading/>}
        {item && <div className='bg-white flex flex-col  w-[100%] md:w-[80%] justify-start items-center rounded-lg h-[99%]'>
          <div className="flex items-center justify-center rounded-t p-9">
            <h3 className="text-lg md:text-2xl font-bold text-black">
              {t("editItem")}
            </h3>
          </div>
          <div className="flex flex-col gap-4 w-[100%]  h-[90%] overflow-scroll" onSubmit={onSubmit}>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("barcode")}
              </p>
              <input
                type="text"
                name="name"
                disabled={true}
                value={item.barcode}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8] bg-gray-300 cursor-not-allowed  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("name")}
              </p>
              <input
                onChange={onChange}
                type="text"
                name="name"
                value={item.name}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("template")}
              </p>
              <div className='w-full'>
                <Select
                  options={templates}
                  value={{label:item.attrName, value: item.attrName}}
                  onChange={(selection)=> setItem(item =>({...item, attrName: selection.value}))}
                  classNames={{
                    menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-[#c7c7c8] overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-[#c7c7c8] px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("price")}
              </p>
              <input
                onChange={onChange}
                type="text"
                name="price"
                value={item.price}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("originalPrice")}
              </p>
              <input
                onChange={onChange}
                type="text"
                name="originalPrice"
                value={item.originalPrice}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("unit")}
              </p>
              <input
                onChange={onChange}
                type="text"
                name="unit"
                value={item.unit}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <input id="imageInput" hidden type="file" accept='image/*' onChange={imageUploadHandle} />
              <label
                className='w-15 h-5 absolute top-11 left-2  cursor-pointer'
                htmlFor="imageInput"
                
              >
                <img htmlFor="imageInput"  src={AddImage} width={24}/>
              </label>
              <label
                className='w-15 h-5 absolute top-11 left-10  cursor-pointer'
                onClick={() => {
                  setUploadSelectModal({
                    show:true,
                    type:"image"
                  });
                }}               
              >
                <img  src={galleryIcon} width={24}/>
              </label>
              <p
                className="block text-sm text-black font-bold"
              >
                {t("imageLink")}
              </p>
              <input
                onChange={onChange}
                type="text"
                name="imageLink"
                value={item.imageLink}
                className="h-10 pl-16 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
              <h2 className='text-red-500 font-bold text-md'>{imageError}</h2>
            </div>
            {imageVideoPreview.img && <div className='flex w-full justify-center h-[50%]'>
              <div className='w-[10%]'>
                {<PlaylistElement element={{type:"img", content:imageVideoPreview.img}}/>}
              </div>
            </div>}
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <input id="videoInput" hidden type="file" accept='video/*' onChange={videoUploadHandle} />
              <label
                className='w-15 h-5 absolute top-11 left-2 cursor-pointer'
                htmlFor="videoInput"
                
              >
                <img htmlFor="videoInput" src={AddVideo} width={24}/>
              </label>
              <p
                className="block text-sm text-black font-bold"
              >
                {t("videoLink")}
              </p>
              <label
                className='w-15 h-5 absolute top-11 left-10  cursor-pointer'
                onClick={() => {
                  setUploadSelectModal({
                    show:true,
                    type:"video"
                  });
                }}               
              >
                <img  src={galleryIcon} width={24}/>
              </label>
              <input
                onChange={onChange}
                type="text"
                name="videoLink"
                value={item.videoLink}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
              <h2 className='text-red-500 font-bold text-md'>{videoError}</h2>
            </div>
            {imageVideoPreview.video && <div className='flex w-full justify-center h-[50%]'>
              <div className='w-[10%]'>
                {<PlaylistElement element={{type:"video", content:imageVideoPreview.video}}/>}
              </div>
            </div>}
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <input id="powerpoint-input" hidden type="file" accept='.pptx' onChange={powerpointUploadHandle} />
              <label
                className='w-15 h-5 absolute top-11 left-2 cursor-pointer'
                htmlFor="powerpoint-input"
                
              >
                <img htmlFor="powerpoint-input" src={AddVideo} width={24}/>
              </label>
              <p
                className="block text-sm text-black font-bold"
              >
                PowerPoint Link
              </p>
              <input
                onChange={onChange}
                type="text"
                name="powerPointLink"
                value={item.powerPointLink}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
              <h2 className='text-red-500 font-bold text-md'>{videoError}</h2>
            </div>
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <input id="pdf-input" hidden type="file" accept='.pdf' onChange={uploadPdfHandle} />
              <label
                className='w-15 h-5 absolute top-11 left-2 cursor-pointer'
                htmlFor="pdf-input"
                
              >
                <img htmlFor="pdf-input" src={AddVideo} width={24}/>
              </label>
              <p
                className="block text-sm text-black font-bold"
              >
                PDF Link
              </p>
              <input
                onChange={onChange}
                type="text"
                name="pdfLink"
                value={item.pdfLink}
                className="h-10 pl-8 text-black border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
              <h2 className='text-red-500 font-bold text-md'>{videoError}</h2>
            </div>
            
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                URL / Youtube 
              </p>
              <input
                onChange={onChange}
                type="text"
                name="IframeLink"
                value={item.IframeLink}
                className="h-10 pl-8 text-black border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 1
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature1"
                value={item.custFeature1}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 2
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature2"
                value={item.custFeature2}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 3
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature3"
                value={item.custFeature3}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 4
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature4"
                value={item.custFeature4}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 5
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature5"
                value={item.custFeature5}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 6
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature6"
                value={item.custFeature6}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 7
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature7"
                value={item.custFeature7}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 8
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature8"
                value={item.custFeature8}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 9
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature9"
                value={item.custFeature9}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 10
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature10"
                value={item.custFeature10}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 11
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature11"
                value={item.custFeature11}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 12
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature12"
                value={item.custFeature12}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 13
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature13"
                value={item.custFeature13}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 14
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature14"
                value={item.custFeature14}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 15
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature15"
                value={item.custFeature15}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 16
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature16"
                value={item.custFeature16}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 17
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature17"
                value={item.custFeature17}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 18
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature18"
                value={item.custFeature18}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
            {/*I showed this to my boss today, and he thought this was fine... дурдом блять X_X */}
            <div className="flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="block text-sm text-black font-bold"
              >
                {t("description")} 19
              </p>
              <input
                onChange={onChange}
                type="text"
                name="custFeature19"
                value={item.custFeature19}
                className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
              />
            </div>
          </div>
          <button className='btn mt-5 w-[100%]' onClick={onSubmit}>
            {t("save")}
          </button>
        </div>}
      </div>
    </>
  )
}

export default ItemEditPage
