import axios from "axios"

export const editStoreApi = async (token, data) => {
  const result = await axios.put(
    `https://backend.konekt.vip/store/editStore`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return result.data;
}

export const createStoreApi = async (token, data) => {
  const result = await axios.post(
    `https://backend.konekt.vip/store/createStore`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return result.data;
}

export const getStoresApi = async (token) => {
  const result = await axios.get(`https://backend.konekt.vip/store/getStores`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}


export const getStoreDetails = async (token, storeId) => {
  const result = await axios.post(`https://backend.konekt.vip/store/getStoreDetails/`,
    {
      storeId
    },
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}