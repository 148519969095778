import React from 'react'

function elementParse(elements) {
  if(elements.type === 'video') {
    if(elements?.content?.includes(".mp4") || elements?.content?.includes(".webm"))
      return <video controls width="400"  preload="metadata" onClick={(e) => {e.stopPropagation()}}>
          <source  src={elements?.content + "#t=1.0"} type="video/mp4" />
        </video>
      
  }
  if(elements.type === "img" || elements.type === "viewTemplateImage" || elements.type === "image") {
    return (
      <img 
        src={elements?.content}
      />
    )
  }
  return <h2 className='text-white font-bold text-xl'>
    {elements.type}
  </h2>
}

const PlaylistElement = ({element}) => {
  return (
    <div className='w-[100%] h-full rounded-lg p-2'>
      <div className='h-16 md:h-[75%]  w-full bg-slate-900 flex justify-center items-center overflow-clip'>
        {elementParse(element)}    
      </div>
      <div className='h-12 w-full rounded-b-lg bg-gray-500 border-gray-300 border-t-2 flex justify-center items-center'>
        <h2 className='text-white font-bold'>{element.type}</h2>
      </div>
    </div>
  )
}

export default PlaylistElement