import React, { useState } from 'react'
import AndroidRotateModal from './AndroidRotateModal'
import AndroidMessageModal from './AndroidMessageModal'
import AndroidDeviceDetailsModal from './AndroidDeviceDetailsModal'
import AndroidKeyControls from './AndroidKeyControls'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { refreshAndroidDevice } from '../../api/AndroidKeyApi';

import Delete from '../../static/delete.png';
import RefreshLogo from '../../static/refresh.png';
import GreenLogo from '../../static/green.png';
import RedLogo from '../../static/red.png';
import LoadingAnim from '../../static/loading.gif';
import playlistLogo from '../../static/playlistLogo.png';
import upldatePlaylistLogo from '../../static/updatePlaylist.png';
import ViewDetailsIcon from '../../static/view-details.png';
import PreviewIcon from '../../static/eye.png';
import ControlsIcon from '../../static/equalizer.png';
import MessageIcon from '../../static/email.png';
import RotationIcon from '../../static/rotate.png';

const AndroidKeyBox = ({androidKey, setUnbind, addRemoveToList, checked, setChangePlaylistId}) => {
  const [showControlsModal, setShowControlsModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showRotationModal, setShowRotationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [keyControls, setKeyControls] = useState({
    volume: androidKey?.controls?.volume || 0,
    brightness: androidKey?.controls?.brightness || 0,
  });
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  return (
    <div
      className='flex flex-col justify-end items-center text-center w-[100%] bg-[#fdfdfd] p-5 gap-1 shadow-md'
    >
       {showRotationModal && <AndroidRotateModal mac={androidKey.mac} deviceRotation={androidKey.rotation} setShowModal={setShowRotationModal}/>}
      {showMessageModal && <AndroidMessageModal mac={androidKey.mac} setShowModal={setShowMessageModal}/>}
      {showDetailsModal && <AndroidDeviceDetailsModal setShowModal={setShowDetailsModal} mac={androidKey.mac} code={androidKey.code} androidkey={androidKey}/>}
      {showControlsModal && <AndroidKeyControls setShowModal={setShowControlsModal} code={androidKey.code} controls={keyControls} setControls={setKeyControls} mac={androidKey.mac}/>}
      <div
        className='w-full flex flex-row items-center justify-center '
      >    
        <h2 className='text-black font-bold'>Mac: {androidKey.mac}</h2>
      </div>
      <div
        className='w-full flex flex-row items-center justify-center gap-1'
      >    
        {androidKey.name && <h2 className='text-black font-bold'>Name: {androidKey.name}</h2>}
      </div>
      <div
        className='w-full flex flex-row items-center justify-center gap-1'
      >
        {androidKey.code && <h2 className='text-black font-bold'>Code: {androidKey.code}</h2>}
        {androidKey.deviceId && <h2 className='text-black font-bold'>Playlist: {androidKey.deviceId}</h2>}
      </div>
      <div
        className='w-full flex flex-row items-center justify-center gap-1'
      >
        {androidKey.connected 
          ? <h2 className='text-green-400 font-bold'>Online</h2>
          : androidKey.lastActive && <h2 className='text-red-500 font-bold'>Last Active: {androidKey.lastActive}</h2>
        }
      </div>
      <div
        className='w-full flex flex-row items-center justify-center gap-1  rounded-b-lg bg-gray-200 p-2'
      >
        {loading 
        ? <div className='w-full flex flex-row justify-center items-center'>
            <img src={LoadingAnim} className='w-5'/>
        </div>
        :<>
          <div className='w-full flex items-center justify-center gap-2'>
            <button
              onClick={async () => {
                setLoading(true)
                await refreshAndroidDevice(user.token,{mac: androidKey.mac})
                setLoading(false)
              }}
            >
              <img src={RefreshLogo} className='w-5'/>
            </button>
            <button
              onClick={() => setShowControlsModal(true)}
            >
              <img src={ControlsIcon} className='w-5'/>
            </button>
            <button
              onClick={() => setShowDetailsModal(true)}
            >
              <img src={ViewDetailsIcon} className='w-5'/>
            </button>
            <button  
              onClick={()=> {setUnbind(androidKey.code)}}
            >
              <img src={Delete}/>
            </button>
          
            <img
              src={upldatePlaylistLogo} 
              className='w-5 cursor-pointer'
              onClick={() => setChangePlaylistId(androidKey)}
            />
            <img
              src={MessageIcon} 
              className='w-5 cursor-pointer'
              onClick={() => setShowMessageModal(true)}
            />
            <img
              src={RotationIcon} 
              className='w-5 cursor-pointer'
              onClick={() => setShowRotationModal(true)}
            />

          </div>
        </>}
      </div>
    </div>
  )
}

export default AndroidKeyBox