import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { 
  deviceSetRotationTime,
  deviceSetShowUnBound,
  getDeviceInfo,
  refreshPlaylistForDevices,
  setShowDeviceIdApi 
} from '../api/deviceApi';
import Up from '../static/up.png';
import Back from '../static/chevron_right.png';

import Down from '../static/down.png';
import AddIcon from '../static/updatePlaylist.png';
import DeviceDisplay from '../components/device/DeviceDisplay';
import { clearDevice, logoutUser, setDevice, setDeviceShowId, setLoadingState, setShowUnBound } from '../store';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getAndroidDevicesFromDevice } from '../api/AndroidKeyApi';
import AndroidDeviceBox from '../components/androidKey/AndroidDeviceBox';
import UnbindAndroidKeyModal from '../components/androidKey/UnbindAndroidKeyModal';
import AddAndroidKeyPlaylistModal from '../components/androidKey/AddAndroidKeyPlaylistModal';
import UploadDisplayModal from '../modals/upload/UploadDisplayModal';

const DeviceEditPage = () => {
  const {deviceId} = useParams();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [displayToOverwrite, setDisplayToOverwrite] = useState({
    displayId:"",
    show:false
  });

  const [androidDevices, setAndroidDevices] = useState([]);
  const [showUnbindModal, setShowUnbindModal] = useState({
    show: false,
    code: ""
  });
  const [showAddAndroidDevice, setShowAddAndroidDevice] = useState(false);
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const device = useSelector(state => state.device);
  const [deviceRotationTime, setdeviceRotationTime] = useState(10);

  const getDevice = async () => {
    try {
      const res = await getDeviceInfo(user.token, deviceId, url);
      setdeviceRotationTime(res.deviceRotationTime)
      dispatch(setDevice(res));
    } catch (error) {
      dispatch(logoutUser());
      console.log(error)
    }
  }

  const getAndroidDevices = async () => {
    try {
      const res = await getAndroidDevicesFromDevice(user.token, {deviceId});
      setAndroidDevices(res);
    } catch (err) {
      console.log(err)
    }
  }

  const refreshDevices = async () => {
    try {
      dispatch(setLoadingState(true));
      await refreshPlaylistForDevices(user.token, {deviceId});
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
      getDevice();
  }, [])

  useEffect(() => {
    if(!showUnbindModal.show && !showAddAndroidDevice) {
      getAndroidDevices();
    }
  }, [showUnbindModal, showAddAndroidDevice])


  

  if(device.loading) {
    return <h2>loading...</h2>
  }


  return (
    <>
      <div className='flex flex-row bg-[#2D5186] p-5 w-full justify-start items-center overflow-x-clip'>
        <div className='flex cursor-pointer' onClick={() => {navigate(-1)}}>
          <img  src={Back}/>
          
        </div>
      </div>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='bg-white w-full h-full flex flex-col justify-start items-center  md:p-5 gap-2 overflow-y-scroll overflow-x-clip pb-24'>
      {showUnbindModal.show && <UnbindAndroidKeyModal code={showUnbindModal.code} setShowModal={(showState) => {
        setShowUnbindModal(unbinModal => ({...unbinModal, show:showState}))
      }}/>}
      {displayToOverwrite.show && <UploadDisplayModal displayId={displayToOverwrite.displayId} setShowModal={(show) => setDisplayToOverwrite(displayOverWrite => ({...displayOverWrite, show: show}))}/>}
      {showAddAndroidDevice && <AddAndroidKeyPlaylistModal deviceId={deviceId} setShowModal={setShowAddAndroidDevice} />}
      <div className='w-full bg-white px-2 py-4 flex flex-col  justify-center items-center'>
        <div className='w-full flex flex-col gap-2 justify-start items-start'>
	        <div className="w-full pb-4 border-b border-gray-200 flex flex-row justify-between md:justify-center items-center">
	          <h2 className='font-bold text-lg gap-2 mx-2'>{t("profileId")}</h2>
            <h2 className='font-bold text-lg gap-2 mx-2'>{device.deviceId}</h2>
	        </div>
          
          <div dir='ltr' className="w-full p-2 flex flex-row gap-3 justify-between items-center text-center border-b border-gray-200 ">
            <input 
              type="checkbox"
              name="usesComax"
              checked={device.showDeviceId}
              className="w-5 h-5 text-blue-700 bg-blue-400 border-gray-300 rounded
              focus:ring-blue-700 dark:focus:ring-blue-700 dark:ring-offset-gray-800
                focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[3px]"
              onChange={async (e) => {
                try {
                  await setShowDeviceIdApi(user.token,url, {
                    deviceId,
                    showDeviceId: !device.showDeviceId
                  })
                  dispatch(setDeviceShowId(!device.showDeviceId));
                } catch (err) {
                  console.log(err)
                }
              }}
            />
            <p className="font-bold text-black text-lg dark:text-gray-300">{t("showProfileId")}</p>
          </div>
          <div dir='ltr' className="w-full p-2 flex gap-3 justify-between border-b border-gray-200 items-center text-center my-2">
            <input 
              type="checkbox"
              name="usesComax"
              checked={device.showUnBound}
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded
              focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[3px]"
              onChange={async (e) => {
                try {
                  await deviceSetShowUnBound(user.token,url, {
                    deviceId,
                    showUnBound: !device.showUnBound
                  })

                  dispatch(setShowUnBound(!device.showUnBound));
                } catch (err) {
                  console.log(err)
                }
              }}
            />
            <p className="font-bold text-black text-lg dark:text-gray-300">{t("showUnboundItems")}</p>
          </div>
        </div>
        <div  className="w-full p-2 flex flex-col gap-3 border-b border-gray-200 text-center my-2">
          <div className='w-full flex gap-2 items-center justify-center'>
            <button 
              onClick={() => {setShowAddAndroidDevice(true)}}
            >
              <img src={AddIcon} className='w-8'/>
            </button>
            <h2 className='text-lg font-bold '>
              {t("boundDevices")}
            </h2>
          </div>

          <div className='rounded-2xl w-full grid grid-cols-2 md:grid-cols-6 gap-2  bg-gray-100 p-2  overflow-scroll'>
            
            {androidDevices.map(dev => {
              return <>
                <AndroidDeviceBox 
                  androidDevice={dev}
                  deleteFunc={() => {
                    setShowUnbindModal({
                      show:true,
                      code: dev.code
                    })
                  }}
                />
              </>
            })}  
            
          </div>
        </div>
        <div className="flex flex-col gap-3 justify-between  w-full px-3 ">
          <div className='flex justify-between md:justify-center items-center text-center align-middle gap-4' dir='ltr'>
            <button 
              onClick={refreshDevices}
              className='px-4 py-2 border-[#2D5186] border-[1px] rounded-xl hover:bg-gray-100 active:bg-gray-200 transition-all'
            >
              {t("refreshDevice")}
            </button>
            <div className='border-[1px] rounded-md min-w-[25%] md:min-w-[0%] border-[#2D5186] p-2 flex flex-row gap-3 justify-between items-center text-center'>
              <button
                className=''
                disabled={deviceRotationTime < 2}
                onClick={async () => {
                  try {
                    await deviceSetRotationTime(user.token, url, {
                      deviceRotationTime:deviceRotationTime-1,
                      deviceId
                    });
                    setdeviceRotationTime(time => time -1);
                  } catch (err) {
                    console.log(err)
                  }
                }}
              >
                <img src={Down}/>
              </button>
              <h2>{deviceRotationTime}</h2>
              <button
                className=''
                disabled={deviceRotationTime > 98}
                onClick={async () => {
                  try {
                    await deviceSetRotationTime(user.token, url, {
                      deviceRotationTime:deviceRotationTime+1,
                      deviceId
                    });
                    setdeviceRotationTime(time => time +1);
                  } catch (err) {
                    console.log(err)
                  }
                }}
              >
                  <img src={Up}/>
              </button>
            </div>
            <p className="font-bold text-black text-lg dark:text-gray-300 mt-1">{t("rotationTime")}</p>
          </div>
        </div>
      </div>
      
        <div className='grid grid-cols-1 lg:grid-cols-3 w-full justify-start items-start gap-5 p-5'>
          {device.displayIds.map((value,index) => {
            let active = device.deviceRotation.includes(index);
            return <DeviceDisplay
              key={value + index}
              posNumber={index}
              display={value}
              barcodes={device.barcodes[index]}
              displayIndex={index}
              active={active}
              deviceId={deviceId}
              storeId={device.storeId}
              setDeviceOverwrite={(displayId) => {
                setDisplayToOverwrite({
                  displayId,
                  show:true
                });
              }}
            />
          }
            
          )}
        </div>
        <Link
           to="/device/createDisplay"
        >
          <button 
            className='btn text-lg shadow-lg p-2'
          >
            {t("addDisplay")}
          </button>
        </Link>
      </div>
    </>
  )
}

export default DeviceEditPage
