import React, { useEffect, useState } from 'react'
import { getAlertSettings, updateAlertSettings } from '../api/AlertApi';
import { useSelector } from 'react-redux';
import Header from '../components/layout/Header';
import Select from 'react-tailwindcss-select';
import AddIcon from '../static/addB.png';
import RemoveIcon from '../static/crossW.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const TimeOptions = [
  {label:"30 Minutes", value:"30m"},
  {label:"1 Hour", value:"1h"},
  {label:"3 Hours", value:"3h"},
  {label:"6 Hours", value:"6h"},
  {label:"12 Hours", value:"12h"},
  {label:"24 Hours", value:"24h"},
]

const timeDic = {
  "30m":"30 Minutes",
  "1h":"1 Hour",
  "3h":"3 Hours",
  "6h":"6 Hours",
  "12h":"12 Hours",
  "24h":"24 Hours"
}

const AlertSettingsPage = () => {
  const [settings, setSettings] = useState({
    emails:[],
    slack:"",
    alertOffline:false,
    noPlaylistAttached: false,
    alertErrors: false,
    time: "30m",
  });
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [emailToAdd, setEmailToAdd] = useState("");
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const addEmail = () => {
    if(emailToAdd) {
      setSettings(settings => {
        let newSettings = {...settings}
        if(newSettings.emails.findIndex(em => em === emailToAdd) < 0) {
          newSettings.emails.push(emailToAdd);
        } 
        setEmailToAdd("");
        return newSettings;
      });
    }
  }
  const user = useSelector(state => state.user);

  const saveAlertSettings = async () => {
    try {
      const settingsToSend = {...settings, "storeId":storeSelection};
      await updateAlertSettings(user.token, settingsToSend);
      navigate('/');
    } catch (err) {
      console.log(err)
    }
  }
  const fetchAlertSettings = async () => {
    try {
      console.log(storeSelection)
      const res = await getAlertSettings(user.token, {storeId: storeSelection});
      console.log(res);
      if(res) {
        setSettings(res);
      }
      if(!res) {
        setSettings({
          emails:[],
          slack:"",
          alertOffline:false,
          noPlaylistAttached: false,
          alertErrors: false,
          time: "30m"
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    console.log(storeSelection);
    fetchAlertSettings();
  }, [storeSelection]);


  const removeEmail = (email) => {
    setSettings(settings => {
      let newSettings = {...settings}
      newSettings.emails = newSettings.emails.filter(em => em !== email);
      setEmailToAdd("");
      return newSettings;
    });
  }
  const onChange = (e) => {
    setSettings(settings => ({...settings, [e.target.name]:e.target.value}))
  }

  return (
    <>
      <Header showBack={true} backTitle={""}/>
      <div className='w-full h-full flex flex-col items-center overflow-scroll gap-5'>
        <h2 className='text-black font-bold text-2xl'>Alert Settings</h2>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-bold text-xl'>Alert Timing:</h2>
          <Select
            value={{label:timeDic[settings.time]}}
            options={TimeOptions}
            onChange={(selection)=> setSettings(settings => ({...settings, time: selection.value}))}
            classNames={{
              menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
              menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>
        <div className='w-[50%] flex flex-col items-start gap-2'>
          <h2 className='text-black font-bold text-xl'>Slack Link:</h2>
          <input
            type="text"
            onChange={(e) => {
              setSettings(settings => ({...settings, slack:e.target.value}));
            }}
            value={settings.slack}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className='w-[50%] flex flex-col gap-2 overflow-scroll'>
          <h2 className='text-black font-bold text-xl'>Emails:</h2>
          {settings.emails.map(email => (<div className='flex p-2 items-center justify-between bg-gray-400 rounded-md w-full border-2 border-gray-500'>
            <h2 className='text-white font-bold'>{email}</h2>
            <img className='cursor-pointer' width={15} src={RemoveIcon} onClick={() => removeEmail(email)}/>
          </div>))}
          <div className='relative w-full flex items-center'>
            <label
              className='w-15 h-5 absolute top-4 left-2  cursor-pointer'
              htmlFor="imageInput"
              
            >
              <img src={AddIcon}  width={24} onClick={addEmail}/>
            </label>
              
            <input
              type="text"
              onChange={(e) => {
                console.log(e.target.value)
                setEmailToAdd(e.target.value);
              }}
              value={emailToAdd}
              className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
            />  
          </div>
        </div>
        
        <div className='w-[50%] flex items-center gap-2'>
          <input 
            type='checkBox'
            checked={settings.alertOffline}
            onChange={async (e) => {
              setSettings(settings => ({...settings, alertOffline: !settings.alertOffline}))
            }}
          />
          <h2 className='text-black font-bold text-lg'>Alert Offline</h2>
        </div>
        <div className='w-[50%] flex items-center gap-2'>
          <input 
            type='checkBox'
            checked={settings.noPlaylistAttached}
            onChange={async (e) => {
            setSettings(settings => ({...settings, noPlaylistAttached: !settings.noPlaylistAttached}))
            }}
          />
          <h2 className='text-black font-bold text-lg'>Alert No Playlist Attached</h2> 
        </div>
        <div className='w-[50%] flex items-center gap-2'>
          <input 
            type='checkBox'
            checked={settings.alertErrors}
            onChange={async (e) => {
            setSettings(settings => ({...settings, alertErrors: !settings.alertErrors}))
            }}
          />
          <h2 className='text-black font-bold text-lg'>Alert Errors </h2> 
        </div>
        <button 
          className='btn mt-5 w-[51%] hover:bg-blue-600 transition-all'
          onClick={() => {
            saveAlertSettings();
          }}
        >
          {t("save")}
        </button>
      </div>

    </>
  )
}

export default AlertSettingsPage