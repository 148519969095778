import React, { useEffect, useState } from 'react'
import { getUploadsApi, uploadImage, uploadVideo } from '../api/uploadApi';
import { useSelector } from 'react-redux';
import UploadRow from '../modals/upload/UploadRow';
import Header from '../components/layout/Header';
import UserSelect from '../components/admin/UserSelect';
import Select from 'react-tailwindcss-select';
import DeleteUploadModal from '../modals/upload/DeleteUploadModal';
import Back from '../static/chevron_right.png'
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../modals/Loading';
import EditUploadName from '../modals/upload/EditUploadName';
import AddImage from '../static/gallery-add.png';
import AddVideo from '../static/video-horizontal.png';
import LoadingSpinner from '../static/loading.gif';

const typeOptions = [
  {label: "Image", value:"image"},
  {label: "Video", value:"video"},
]
const FileMangmentPage = () => {
  const [fetchingParams, setFetchingParams] = useState({
    type:"image",
    limit: 20,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show:false,
    link:""
  });
  const [editNameDialog, setEditNameDialog] = useState({
    show:false,
    link:""
  });
  const [error, setError] = useState("");
  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    if(file.type != "video/mp4") {
      setError("Videos can be mp4 only")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', user);
    form.append('selectedStore', selectedStore);
    try {
      setLoading(true)
      const result = await uploadVideo(user.token, "", form);
      fetchData(fetchingParams.limit);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    if(file.type != "image/jpeg" && file.type != "image/png") {
      setError("Images must be .jpgs or .pngs only")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', user);
    form.append('selectedStore', selectedStore);
    try {
      setLoading(true)
      const result = await uploadImage(user.token, "", form);
      fetchData(fetchingParams.limit);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const [showMore, setShowMore] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const navigate = useNavigate();
  const fetchData = async (limit) => {
    try {
      setLoading(true);
      const dataRes = await getUploadsApi(
        user.token, 
        {
          type: fetchingParams.type,
          limit: limit,
          storeId: selectedStore
        }
      );
      setUploads(dataRes.uploads);
      setShowMore(dataRes.showMore);
      setLoading(false);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if(!deleteDialog.show && !editNameDialog.show) {
      fetchData(fetchingParams.limit);
    }
  }, [fetchingParams, selectedStore, deleteDialog.show, editNameDialog.show])
  // drop the dropdown
  return (
    <div id='scrollable' className='flex flex-col w-full h-full overflow-scroll'>
      {loading && <Loading/>}
      {deleteDialog.show && <DeleteUploadModal setShowModal={setDeleteDialog} linkToDelete={deleteDialog.link}/>}
      {editNameDialog.show && <EditUploadName setShowModal={setEditNameDialog} link={editNameDialog.link}/>}
        <div className='w-full p-2 flex gap-2 items-center justify-between border-b-2 bg-[#2D5186]'>
          <div className='flex cursor-pointer' onClick={() => {navigate(-1)}}>
            <img src={Back} className='w-10'/>
          </div>
          <div className='w-[25%] items-center gap-2 hidden md:flex'>
            {user.superAdmin   && <UserSelect/>}

          </div>
        </div>
        
        {fetchingParams.type == "image" && <div className='w-full flex justify-center items-center p-2'>
          <input id="imageInput" hidden type="file" accept='image/*' onChange={handleUploadImage} />
          <label
            className='flex w-full justify-center cursor-pointer gap-2'
            htmlFor="imageInput"
            
          >
            {!loading ? <>
              <img htmlFor="imageInput"  src={AddImage} width={30}/>
              <h2 className='text-black font-bold text-xl'>Add Image +</h2>
            </> :  <img src={LoadingSpinner} width={20} />}
          </label>
        </div>}
        {fetchingParams.type == "video" && <div className='w-full flex items-center justify-start p-2'>
          <input id="videoInput" hidden type="file" accept='video/*' onChange={handleUploadVideo} />
          <label
            className='flex w-full justify-center cursor-pointer gap-2'
            htmlFor="videoInput"  
          >
            {!loading ? <>
              <img htmlFor="videoInput" src={AddVideo} width={30}/>
              <h2 className='text-black font-bold text-xl'>Add video +</h2>
            </> : <img src={LoadingSpinner} width={10} />}

          </label>
        </div>}
        <div className='justify-center  items-center gap-2 flex'>
          
            <h2 className='text-white font-bold'>
              Type:
            </h2>
            <div className=''>

              <Select
                options={typeOptions}
                value={{value: fetchingParams.type, label: fetchingParams.type}}
                onChange={(selection)=> {
                  setFetchingParams(fetchPrms => ({...fetchPrms, type: selection.value}))
                }}
                classNames={{
                  menu:"absolute w-[300px] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[300px] h-[50px] bg-white border-2 border-black px-2 justify-center z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
          </div>
        {error && <div className='w-full flex items-center justify-center'>
          <h2 className='text-red-500 font-bold text-lg'>{error}</h2>
        </div>}
        <InfiniteScroll
          dataLength={uploads.length}
          next={() => {
            
            fetchData(fetchingParams.limit + 20);
            setFetchingParams(fetchingParams => ({...fetchingParams, limit: fetchingParams.limit + 20}))
          }}
          
          hasMore={showMore}
          scrollableTarget={"scrollable"}
        >

          <table className="w-full items-center">
            <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2 border-gray-300 w-screen">
              <tr className='w-full'>
                <th
                  scope="col" 
                  className=" w-[30%] hover:cursor-pointer"              
                >
                  Image
                </th>
                <th
                  scope="col" 
                  className="w-[20%] px-6 py-4 hover:cursor-pointer"              
                >
                  Name
                </th>
                <th
                  scope="col" 
                  className="w-[70%] px-6 py-4 hover:cursor-pointer hidden md:table-cell"              
                >
                  Link
                </th>
                <th
                  scope="col" 
                  className="w-[5%] px-6 py-4 hover:cursor-pointer"              
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className='w-screen h-full'>
              {uploads.map(upld => (<UploadRow upload={upld} setDeleteDialog={setDeleteDialog} setEditNameDialog={setEditNameDialog}/>))}
            </tbody>
              
        </table>
      </InfiniteScroll>

    </div>
  )
}

export default FileMangmentPage