import React, { useEffect, useState } from 'react'
import { getSingleAndroidDevice, refreshAndroidDevice } from '../api/AndroidKeyApi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../components/layout/Header';
import CommandComponent from '../components/androidKey/CommandComponent';
import AndroidMessageModal from '../components/androidKey/AndroidMessageModal';
import MessageIcon from '../static/email.png';
import Rotation from '../static/rotate.png';
import OnlineIcon from '../static/green.png';
import OfflineIcon from '../static/red.png';
import RefreshLogo from '../static/refresh.png';
import LoadingAnim from '../static/loading.gif';
import AndroidRotateModal from '../components/androidKey/AndroidRotateModal';

const AndroidDevicePage = () => {
  const [device, setDevice] = useState();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showRotationModal, setShowRotationModal] = useState(false);
  const user = useSelector(state => state.user);
  const {code} = useParams();


  const fetchData = async () => {
    try {
      const res = await getSingleAndroidDevice(user.token,{code});
      setDevice(res);
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData();  
  }, [])
  return (
    <>
      {showMessageModal && <AndroidMessageModal mac={device.mac} setShowModal={setShowMessageModal}/>}
      {showRotationModal && <AndroidRotateModal mac={device.mac} setShowModal={setShowRotationModal}/>}
      
      <Header showBack={true} backTitle={""}/>
      {device && <div className='w-full h-full flex flex-col items-center overflow-scroll gap-5 p-5 bg-gray-100'>
        <div className='w-[80%] rounded-lg bg-white p-2 flex flex-col items-center border-2'>
          <h2 className='text-black font-bold text-2xl'>Device Details</h2>
          <div className='w-full flex items-center justify-center gap-2 px-5 mt-2'>
            <div className='flex items-center gap-2'>
              <h2 className='text-black font-extrabold text-lg'>
                Code:
              </h2>
              <h2 className='text-black font-semibold text-lg'>
                {device.code}
              </h2>
            </div>
            <div className='flex items-center gap-2'>
              <h2 className='text-black font-extrabold text-lg'>
                Mac Address:
              </h2>
              <h2 className='text-black font-semibold text-lg'>
                {device.mac}
              </h2>
            </div>
            <div className='flex items-center gap-2'>
              <h2 className='text-black font-extrabold text-lg'>
                Bound Playlist:
              </h2>
              <h2 className='text-black font-semibold text-lg'>
                {device.deviceId}
              </h2>
            </div>
            <div className='flex items-center gap-2'>
              <h2 className='text-black font-extrabold text-lg'>
                Connected:
              </h2>
              <img
                src={device.connected ? OnlineIcon : OfflineIcon}
                className='w-4 mt-1'
              />
            </div>
          </div>
          <div className='w-full flex items-center justify-center'>
            <img className='w-10 cursor-pointer'  src={MessageIcon} onClick={() => setShowMessageModal(true)}/>
            <img className='w-10 cursor-pointer mb-2 ml-4' src={Rotation} onClick={() => setShowRotationModal(true)}/>
            {!refreshLoading ? <button
              onClick={async () => {
                setRefreshLoading(true);
                await refreshAndroidDevice(user.token,{mac: device.mac})
                setRefreshLoading(false);
              }}
            >
              <img src={RefreshLogo} className='w-10'/>
            </button>: <img src={LoadingAnim} className='w-10' />} 
          </div>
        </div>
        <div className='w-[80%] flex flex-col overflow-scroll gap-5 p-5 bg-white border-2 rounded-lg'>
          {device?.deviceDetails && <div className='flex w-full justify-between items-center p-2 gap-2'>
            {device?.deviceDetails?.deviceName && <div className='flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Device Name:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.deviceName}</h2>
            </div>}
            {device?.deviceDetails?.osVersion && <div className='flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>OS Version:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.osVersion}</h2>
            </div>}
            {device?.deviceDetails?.software && <div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>App Version:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.software}</h2>
            </div>}
            {device?.deviceDetails?.IP &&<div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>IP:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.IP}</h2>
            </div>}
            {device?.deviceDetails?.software &&<div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Software Version:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.software}</h2>
            </div>}
            {device?.deviceDetails?.manufacture &&<div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Manufaturer:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.manufacture}</h2>
            </div>}
          </div>}
          {device?.deviceDetails && <div className='flex w-full justify-between items-center p-2 gap-2'>
            {device?.deviceDetails?.ramTotal && <div className='flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Ram Total:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.ramTotal}</h2>
            </div>}
            {device?.deviceDetails?.ramFree &&<div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Ram Free:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.ramFree}</h2>
            </div>}
            {device?.deviceDetails?.height && <div className='flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Screen Height:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.height}</h2>
            </div>}
            {device?.deviceDetails?.width &&<div className=' flex items-center gap-1'>
              <h2 className='text-xl font-extrabold'>Screen Width:</h2>
              <h2 className='text-xl font-semibold'>{device?.deviceDetails?.width}</h2>
            </div>}
          </div>}
        </div>
       {device && <div className='w-[80%] h-[75%] flex flex-col overflow-scroll gap-5 p-5 bg-white border-2 rounded-lg'>
          <CommandComponent androidkey={device}/>
        </div>}
      </div>}
    </>
  )
}

export default AndroidDevicePage