import EditIcon from '../../static/stylus.png';
import RemoveIcon from '../../static/delete.png';

const Store = ({store, setModalToEdit}) => {
  return (
    <tr key={store._id} className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
      <td className="text-black font-bold border-r-2 p-2 ">
        {store.storeId}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {store.name}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {store.zkongAccount}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        <div className='w-full flex items-center justify-center gap-2'>
          <button
            onClick={() => {
              setModalToEdit(store);
            }}
          >
            <img src={EditIcon} className='w-5'/>
          </button>
          <button
          >
            <img src={RemoveIcon} className='w-5'/>
          </button>
        </div>
      </td>
    </tr>
  )
}

export default Store